import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../Const/Url";
import Loader from "../CustomCommons/Loader";

import cover from "./img/cover.png";
import profile from "./img/profile.png";
import { membershipLevels } from "../CustomCommons/status";

const UserInfo = ({ label, info, button }) => {
  return (
    <>
      <div className="row mb-4 user_view_info_P p-0">
        <div className="col-3 p-0">{label}</div>

        {button ? (
          <>
            :<button className="user_view_button ms-1">{button}</button>
          </>
        ) : (
          <div className="col-9 p-0">{info}</div>
        )}
      </div>
    </>
  );
};
const ViewUser = () => {
  const [isLoading, setIsloading] = useState(false);
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [membership, setMemberShip] = useState([]);

  console.log("id----------", id);
  const getSingleUser = () => {
    const url = `${BASE_URL}api/v1/users/inventory/user/${id}`;
    axios
      .get(url)
      .then((res) => {
        setUser(res.data.data);
        // console.log(found);
        setIsloading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  const getMemberShip = () => {
    const url = `${BASE_URL}/api/v1/auth/membership/?mobile=${user?.mobile}`;
    setIsloading(true);
    axios
      .get(url)
      .then(async (res) => {
        const data = await res.data.data.results;
        setMemberShip(data);
        setIsloading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  const genders = ["Male", "Female", "Others"];
  console.log(user);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  useEffect(() => {
    getSingleUser();
  }, [id]);
  useEffect(() => {
    // getMemberShip();
  }, [user]);
  console.log(membership, "23");
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="side_components_container">
      <div className="row ">
        <div className="col-lg-5 col-sm-12 col-xs-12 ">
          <div className="user_view_image p-0">
            <div className="card ">
              <img className="card-img-top " src={cover} alt="" />

              <div className="user_name_info d-flex justify-content-center flex-column">
                <img
                  className="user_name_info_img "
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  src={user?.image ? `${user?.image} ` : profile}
                  alt=""
                />
                <h5 className="user_name_info_name">
                  {user.first_name} {user.last_name}
                </h5>
                <Link
                  className="user_name_info_role text-center"
                  style={{ width: "150px" }}
                >
                  {user?.is_customer
                    ? "Website User"
                    : user?.is_employee
                    ? "Employee"
                    : user.role_name}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-sm-12 col-xs-12  user_view_contents user_view_contents3  p-0">
          <div className=" ">
            <h5 className="user_view_head ">Personal Details</h5>
            <div className="line_user_view mb-4 mt-3"></div>
            <UserInfo label="Email Address" info={`: ${user.email}`} />
            <UserInfo label="Phone Number" info={`: ${user.mobile}`} />

            <UserInfo label="Gender" info={`: ${genders[user?.gender]}`} />

            {/* <UserInfo label="Account Status" button={`${user?.is_active ? 'Active' :'Disable'}`} /> */}
            <div className="row mb-4 user_view_info_P p-0">
              <div className="col-3 p-0">Account Status</div>:{" "}
              <button
                className="user_view_button ms-1"
                style={{
                  background: `${user?.is_active === false && "#749F82"}`,
                }}
              >{`${user?.is_active ? "Active" : "Disable"}`}</button>{" "}
            </div>
            {user?.is_employee && (
              <UserInfo
                label="Discount"
                info={`: ${user.discount_value}
                          ${user?.discount_type === 0 ? " TK" : " %"}`}
              />
            )}

            {/* <UserInfo label="Joining Date" info=": May 7, 2022, 10:34 pm" /> */}
            <UserInfo
              label="Joining Date"
              info={`: ${new Date(user?.created_at).toLocaleDateString(
                "en-US",
                options
              )}`}
            />
            {/* {membership?.length > 0 && (
              <>
                <UserInfo
                  label="Card Number"
                  info={`: ${membership[0]?.card_number}`}
                />
                <UserInfo
                  label="Available Point"
                  info={`: ${membership[0]?.point}`}
                />
                <UserInfo
                  label="Member Type"
                  info={`: ${membershipLevels[membership[0]?.membership_type]}`}
                />
                <UserInfo
                  label="Membership Created"
                  info={`: ${new Date(
                    membership[0]?.created_at
                  ).toLocaleDateString("en-US", options)}`}
                />
              </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
