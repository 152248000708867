import React, { useEffect, useState } from "react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../utils/HandleInputs";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import Select from "react-select";
import eye from "../../assets/Icon/eye.svg";
import swal from "sweetalert";
import { showToast } from "../../utils/ToastHelper";
import * as path from "./../Routes/RoutePaths";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../CustomCommons/Loader";

const EditVoucher = () => {
  const { id } = useParams();
  const history = useHistory();
  const [fileName, setFileName] = useState("");
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const [pds, setPds] = useState([]);

  const [isWebsite, setIsWebsite] = useState(true);
  const [isOutlet, setIsOutlet] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [outletList, setOutletList] = useState([]);
  const [defaultDiscountFor, setDefaulDiscountFor] = useState([]);

  const [excludeCategory, setExcludeCategory] = useState([]);
  const [excludeProduct, setExcludeProduct] = useState([]);

  const [mainOfferType, setMainOfferType] = useState(0);
  const [quantityOrAmount, setQuantityOrAmount] = useState(0);
  const [quantityForCombo, setQuantityForCombo] = useState(0);
  const [amountForCombo, setAmountForCombo] = useState(0);

  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [offerAllData, setOfferAllData] = useState({
    name: "",
    discount_value: "",
    expiry_date: "",
    start_date: "",
    description: "",
    max_usage: 0,
    max_discount: 0,
  });
  const [description, setDescription] = useState("");

  const [selectProduct, setSelectProduct] = useState([]);
  const [invoice_discount_type, setinvoice_discount_type] = useState(0);
  const [invoiceFor, setInvoiceFor] = useState(0);
  const [invoice_CatChoice, setinvoice_CatChoice] = useState(3);
  const [inputQuery, setInputQuery] = useState("");
  const [inputQueryExPd, setInputQueryExPd] = useState("");
  const [inputQuery2, setInputQuery2] = useState("");
  const [productList, setProductsList] = useState([]);
  const [productListExPd, setProductsListExPd] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const discountFor = [
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Website",
      value: "Website",
    },
    {
      label: "Outlet",
      value: "Outlet",
    },
  ];
  const getLabelsFromState = (state) => {
    const labels = [];

    if (state.is_admin) {
      labels.push({ label: "Admin", value: "Admin" });
    }

    if (state.is_outlet) {
      labels.push({ label: "Outlet", value: "Outlet" });
    }

    if (state.is_website) {
      labels.push({ label: "Website", value: "Website" });
    }

    return labels;
  };
  const handleDateChange = (event) => {
    const selectedDate = new Date(event);
    // Set the time to the last hour of the day (23:59)
    selectedDate.setHours(23, 59, 59, 0);
    // Format the date as 'YYYY-MM-DDTHH:mm:ssZ'
    const formattedDate = selectedDate.toISOString();
    console.log("Selected Date:", formattedDate);
    // Update state with the selected date
    return formattedDate;
  };
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data.data;
        setOfferAllData({
          name: data?.title ? data?.title : data?.code,
          discount_value: data?.discount_value,
          expiry_date: "",
          start_date: "",
          description: "",
          max_usage: data?.max_usage,
          max_discount: data?.max_discount,
        });
        setinvoice_discount_type(1);
        setInvoiceFor(data?.discount_for);
        setinvoice_CatChoice(data?.main_category);
        setStartDate(data?.start.split("T")[0]);
        setEndDate(data?.end.split("T")[0]);

        setCategoryswitch(data?.is_active);
        setThumbId(data?.banner);
        setURL(data?.banner_url);
        setMainOfferType(data?.offer_combo_type);
        setQuantityOrAmount(data?.offer_based_on);
        setQuantityForCombo(data?.minimum_purchase_quantity);
        setAmountForCombo(data?.minimum_purchase_amount);
        const newPd = []
        const newCt = []
        const exPd = []
        const exCt = []
        const defValue = []
        setSelectedOutlet(defValue);
        setIsOutlet(data?.is_outlet);
        setIsAdmin(data?.is_admin);
        setIsWebsite(data?.is_website);
        setSelectProduct(newPd);
        setSelectCategory(newCt);
        setExcludeProduct(exPd);
        setExcludeCategory(exCt);
        console.log(exCt);

        // setPds()

        const initialState = {
          is_admin: data?.is_admin,
          is_outlet: data?.is_outlet,
          is_website: data?.is_website,
        };
        const labelsArray = getLabelsFromState(initialState);
        setDefaulDiscountFor(labelsArray);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
        // if (
        //   message === "Invalid token." ||
        //   JSON.parse(err.request.response).code === 401
        // ) {
        // }
      });
  };
  const url = `${BASE_URL}api/v1/promotions/admin/voucher/${id}`;
  useEffect(() => {
    apiFunction(url);
  }, []);
  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_offer/?&limit=30`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_offer/?query=${inputQuery}&&limit=30`;
      }

      axios
        .get(url)
        .then((res) => {
          const result = res.data.data?.results;
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
          }));

          setProductsList(options);
          console.log(options, "156");
          console.log(productList, "156 2");
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  const getProductListExPd = () => {
    if (inputQueryExPd?.length > 1) {
      let url;
      if (
        !inputQueryExPd ||
        inputQueryExPd === "" ||
        inputQueryExPd === undefined
      ) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_offer/`;
      } else if (inputQueryExPd) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_offer/?query=${inputQueryExPd}`;
      }

      axios
        .get(url)
        .then((res) => {
          const result = res.data.data?.results;

          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
          }));

          setProductsListExPd(options);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  const getCategoryList = () => {
    if (inputQuery2?.length > 1) {
      let url;
      if (!inputQuery2 || inputQuery2 === "" || inputQuery2 === undefined) {
        url = `${BASE_URL}api/v1/inventory/inventory/category/?category_type=2`;
      } else if (inputQuery2) {
        url = `${BASE_URL}api/v1/inventory/inventory/category/?category_type=2&query=${inputQuery2}`;
      }
      axios
        .get(url)
        .then((res) => {
          const result = res.data.data.results;
          const options = result.map((curr) => ({
            label: curr.name,
            value: curr.id,
          }));

          setCategoryList(options);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };

  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
  };
  const inputProductExPd = (inputValue) => {
    setInputQueryExPd(inputValue);
  };
  const inputCategory = (inputValue) => {
    setInputQuery2(inputValue);
  };
  useEffect(() => {
    // getProductList();
  }, [inputQuery]);
  useEffect(() => {
    // getProductListExPd();
  }, [inputQueryExPd]);
  useEffect(() => {
    // getCategoryList();
  }, [inputQuery2]);
  const handleMultiInputChange = (newValue, actionMeta) => {
    setSelectProduct(newValue);
  };
  const handleMultiInputChange2 = (newValue, actionMeta) => {
    setSelectCategory(newValue);
  };
  const handleMultiInputChangeExcCat = (newValue, actionMeta) => {
    setExcludeCategory(newValue);
  };
  const handleMultiInputChangeExcProd = (newValue, actionMeta) => {
    setExcludeProduct(newValue);
  };
  const updateVoucher = () => {
    const url = `${BASE_URL}api/v1/promotions/admin/voucher/${id}/`;

    if (offerAllData["name"] === "") {
      showToast("error", "Voucher name is not valid..");
    } 
     else {
      const data = {
        title: offerAllData["name"],
        code: offerAllData["name"],
        discount_type: invoice_discount_type,
        discount_value: offerAllData.discount_value,
        start: startDate,
        end: endDate,
        is_active: Categoryswitch,
        banner: thumbId ? thumbId : null,
        product: selectProduct ? selectProduct.map((obj) => obj.value) : [],
        category: selectCategory ? selectCategory.map((obj) => obj.value) : [],
        main_category: invoice_CatChoice,
        max_usage: 1,
        max_discount: offerAllData["max_discount"],
        is_outlet: true,
        is_website: true,
        is_admin: false,
        outlet: selectedOutlet?.map((item) => item.value),
        exclude_category: excludeCategory
          ? excludeCategory?.map((item) => item.value)
          : [],
        exclude_product: excludeProduct
          ? excludeProduct?.map((item) => item.value)
          : [],
      };

      axios
        .patch(url, data)
        .then((res) => {
          if (res.data.status) {
            showToast("success", "Voucher Updated.");
            history.push("/promotions/voucher-list");
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let key in errorMsg) {
            showToast("error", `${key} : ${errorMsg[key][0]}`);
          }
          showToast("error", message);
        });
    }
  };

  const getDiscountFor = (val, action) => {
    const labels = val;
    const adminPresent = labels.some((item) => item.label === "Admin");
    const websitePresent = labels.some((item) => item.label === "Website");
    const outletPresent = labels.some((item) => item.label === "Outlet");
    // Set states based on label presence
    setIsAdmin(adminPresent);
    setIsWebsite(websitePresent);
    setIsOutlet(outletPresent);
  };

  //   useEffect(() => {
  //     getOutlet();
  //   }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Edit Voucher</h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
            borderBottom: " 0.5px solid #E0E0E0",
            borderRight: " 0.5px solid #E0E0E0",
            borderLeft: "0.5px solid #E0E0E0",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Voucher Code"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={offerAllData["name"]}
                onChange={(e) => handleInputs(e, setOfferAllData)}
              />
            </InputGroup>
          </div>

          <div className="col-12 col-md-6 ">
            <RequiredLabel text={"Discount Type"} />
            <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) => setinvoice_discount_type(e.target.value)}
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={invoice_discount_type}
              >
                
                <option value={1}>Percentage</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-12 d-none">
            <RequiredLabel text={"Discount For"} />
            {/* <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) => setInvoiceFor(e.target.value)}
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={mainOfferType === 1 ? 0 : invoiceFor}
              >
                <option value={0}>Website</option>
                <option value={1}>Outlet</option>
              </Form.Select>
            </div> */}
            <div className="inputgroup_container">
              <Select
                isMulti
                placeholder="---Select---"
                onChange={getDiscountFor}
                options={discountFor}
              />
            </div>
          </div>

          {/* {isOutlet === true && mainOfferType === 0 && (
            <div className="col-12 my-2">
              <RequiredLabel text="Outlet" />

              <div className="inputgroup_container">
                <Select
                  isMulti
                  placeholder="---Select---"
                  onChange={getSelectOutlet}
                  options={outletList}
                />
              </div>
            </div>
          )} */}
          <div className="col-12 col-md-6 d-none">
            <RequiredLabel text={"Category For Offer"} />
            <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) => setinvoice_CatChoice(Number(e.target.value))}
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={invoice_CatChoice}
              >
                <option value={3}>****None****</option>
                <option value={0}>Home Decor</option>
                <option value={1}>In style</option>
                <option value={2}>Both</option>
              </Form.Select>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <RequiredLabel text={"Discount Value (%)"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="discount_value"
                value={offerAllData["discount_value"]}
                onChange={(e) => handleInputs(e, setOfferAllData)}
                type="number"
                min={0}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6 d-none">
            <RequiredLabel text={"Max Usages"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="max_usage"
                min={0}
                value={offerAllData["max_usage"]}
                onChange={(e) => handleInputs(e, setOfferAllData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Max Discount"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="max_discount"
                min={0}
                value={offerAllData["max_discount"]}
                onChange={(e) => handleInputs(e, setOfferAllData)}
              />
            </InputGroup>
          </div>

          {/* {mainOfferType === 1 && (
            <>
              <div className="col-12 col-md-6">
                <RequiredLabel text={"Select Quantity/Amount For Offer"} />
                <div className="mb-3" style={{ height: "47px" }}>
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) =>
                      setQuantityOrAmount(parseInt(e.target.value))
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                    value={quantityOrAmount}
                  >
                    <option value={0}>---Select---</option>
                    <option value={1}>Minimum Quantity For Offer</option>
                    <option value={2}>Minimum Purchace Amount</option>
                  </Form.Select>
                </div>
              </div>
              {quantityOrAmount === 1 ? (
                <div className="col-12 col-md-6">
                  <RequiredLabel text={"Minimum Quantity For Offer"} />
                  <InputGroup className="mb-3" style={{ height: "47px" }}>
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="name"
                      type="number"
                      min={0}
                      value={quantityForCombo}
                      onChange={(e) => setQuantityForCombo(e.target.value)}
                    />
                  </InputGroup>
                </div>
              ) : quantityOrAmount === 2 ? (
                <div className="col-12 col-md-6">
                  <RequiredLabel text={"Minimum Amount For Offer"} />
                  <InputGroup className="mb-3" style={{ height: "47px" }}>
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="name"
                      type="number"
                      min={0}
                      value={amountForCombo}
                      onChange={(e) => setAmountForCombo(e.target.value)}
                    />
                  </InputGroup>
                </div>
              ) : null}
            </>
          )} */}
          <div className="col-12 col-md-6">
            <RequiredLabel text=" Start Date" />
            <div className="position-relative">
              <InputGroup className="mb-3">
                <Form.Control
                  type="date"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  placeholder="Select a date"
                  name="start_date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </InputGroup>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text=" Expiry Date" />
            <div className="position-relative">
              <InputGroup className="mb-3">
                <Form.Control
                  type="date"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  placeholder="Select a date"
                  name="expiry_date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </InputGroup>
            </div>
          </div>

          {/* {invoice_CatChoice === 3 && (
            <>
              <div className="col-12 mb-2">
                <p>Category</p>
                <div className="position-relative">
                  <Select
                    // styles={styles}
                    isMulti
                    placeholder="---Select---"
                    isClearable
                    // defaultValue={selectedOption}
                    onChange={handleMultiInputChange2}
                    onInputChange={inputCategory}
                    options={CategoryList}
                  />
                </div>
              </div>
              { (
                <div className="col-12 mb-2">
                  <p>Exclude Category</p>
                  <div className="position-relative">
                    <Select
                      // styles={styles}
                      isMulti
                      placeholder="---Select---"
                      isClearable
                      // defaultValue={selectedOption}
                      onChange={handleMultiInputChangeExcCat}
                      onInputChange={inputCategory}
                      options={CategoryList}
                    />
                  </div>
                </div>
              )}

              {quantityOrAmount !== 2 && (
                <div className="col-12 ">
                  <RequiredLabel text="Products" />
                  <div className="position-relative">
                    <Select
                      // styles={styles}
                      isMulti
                      placeholder="---Select---"
                      isClearable
                      // defaultValue={selectedOption}
                      onChange={handleMultiInputChange}
                      onInputChange={inputProduct}
                      options={productList}
                    />
                  </div>
                </div>
              )}
              { (
                <div className="col-12 ">
                  <RequiredLabel text="Exclude Products" />
                  <div className="position-relative">
                    <Select
                      // styles={styles}
                      isMulti
                      placeholder="---Select---"
                      isClearable
                      // defaultValue={selectedOption}
                      onChange={handleMultiInputChangeExcProd}
                      onInputChange={inputProductExPd}
                      options={productListExPd}
                    />
                  </div>
                </div>
              )}
            </>
          )} */}

          {invoice_CatChoice === 3 && (
            <>
              <div className="col-12 mb-2 d-none">
                {/* <RequiredLabel text="Category" /> */}
                <p>Category</p>
                <div className="position-relative">
                  <Select
                    // styles={styles}
                    isMulti
                    placeholder="---Select---"
                    isClearable
                    defaultValue={selectCategory}
                    onChange={handleMultiInputChange2}
                    onInputChange={inputCategory}
                    options={CategoryList}
                  />
                </div>
              </div>
            </>
          )}
          {
            <div className="col-12 mb-2 d-none">
              {/* <RequiredLabel text="Category" /> */}
              <p>Exclude Category</p>
              <div className="position-relative">
                <Select
                  // styles={styles}
                  isMulti
                  placeholder="---Select---"
                  isClearable
                  defaultValue={excludeCategory}
                  onChange={handleMultiInputChangeExcCat}
                  onInputChange={inputCategory}
                  options={CategoryList}
                />
              </div>
            </div>
          }
          {invoice_CatChoice === 3 && (
            <>
              {
                <>
                  <div className="col-12 d-none">
                    <RequiredLabel text="Products" />
                    <div className="position-relative">
                      <Select
                        // styles={styles}
                        isMulti
                        placeholder="---Select---"
                        isClearable
                        defaultValue={selectProduct}
                        onChange={handleMultiInputChange}
                        onInputChange={inputProduct}
                        options={productList}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? "blue" : "black",
                            boxShadow: state.isFocused
                              ? "0 0 0px 0.5px rgba(255, 0, 0, 1)"
                              : "none",
                            "&:hover": {
                              borderColor: "blue",
                            },
                          }),
                        }}
                      />
                    </div>
                  </div>
                </>
              }
            </>
          )}

          {quantityOrAmount !== 2 && (
            <>
              {
                <div className="col-12 mt-2 d-none">
                  <RequiredLabel text="Exclude Products" />
                  <div className="position-relative">
                    <Select
                      // styles={styles}
                      isMulti
                      placeholder="---Select---"
                      isClearable
                      defaultValue={excludeProduct}
                      onChange={handleMultiInputChangeExcProd}
                      onInputChange={inputProductExPd}
                      options={productListExPd}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "blue" : "black",
                          boxShadow: state.isFocused
                            ? "0 0 0px 0.5px rgba(255, 0, 0, 1)"
                            : "none",
                          "&:hover": {
                            borderColor: "blue",
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
              }
            </>
          )}

          <div className="row d-flex justify-content-between">
            <p style={{ marginTop: "10px", color: "red" }}>
              NB: Upload image in (height-523px, width-392px) for thumbnail.{" "}
            </p>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>

            {/* ----------status--------- */}
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={updateVoucher}
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button
              onClick={() => history.push("/promotions/voucher-list")}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVoucher;
